exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-accounts-delete-tsx": () => import("./../../../src/pages/accounts/delete.tsx" /* webpackChunkName: "component---src-pages-accounts-delete-tsx" */),
  "component---src-pages-accounts-index-tsx": () => import("./../../../src/pages/accounts/index.tsx" /* webpackChunkName: "component---src-pages-accounts-index-tsx" */),
  "component---src-pages-discord-tsx": () => import("./../../../src/pages/discord.tsx" /* webpackChunkName: "component---src-pages-discord-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-press-tsx": () => import("./../../../src/pages/press.tsx" /* webpackChunkName: "component---src-pages-press-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-spotify-auth-callback-tsx": () => import("./../../../src/pages/spotify_auth_callback.tsx" /* webpackChunkName: "component---src-pages-spotify-auth-callback-tsx" */),
  "component---src-pages-terms-of-service-tsx": () => import("./../../../src/pages/terms-of-service.tsx" /* webpackChunkName: "component---src-pages-terms-of-service-tsx" */)
}

